import { Dialog, Transition } from "@headlessui/react";
import { ArrowRightIcon, XIcon } from "@heroicons/react/solid";
import { graphql, Link, useStaticQuery } from "gatsby";
import React, { Fragment, useState } from "react";


const CareerAvailability = () => {

  const [open, setOpen] = useState(false);

  const data = useStaticQuery(graphql`
    {
      allPrismicJobOpening {
        edges {
          node {
            data {
              slug
              job_title {
                text
              }
              job_location
              salary_ending
              salary_starting
              key_qualifications {
                html
              }
              job_summary {
                html
              }
              education_experience {
                html
              }
              key_responsibilities {
                html
              }
            }
          }
        }
      }
    }
  `);

  return (
    <>
      <main className="contact-options-grid">
        <div className="bg-gray-900 p-2"></div>
        <div className="bg-gray-900 py-4 px-4 sm:px-8 lg:px-12">
          <h3 className="text-2xl font-medium text-gray-300">
            Available Opportunities
          </h3>
        </div>
        <div className="bg-gray-900 p-2"></div>
        {data.allPrismicJobOpening.edges.map((item) => (
          <>
            <div className="bg-gray-900 p-2"></div>
            <div className="bg-gray-900 py-4 px-4 sm:px-8 lg:px-12">
              <ul className="flex flex-row justify-between items-center">
                <li className="text-base md:text-lg font-regular text-gray-400">
                  {item.node.data.job_title.text}
                </li>
                <li className="text-2xl col-span-1">
                  <Link to = {item.node.data.slug}>
                    <button onClick = {() => setOpen(true)}>
                      <ArrowRightIcon className="h-6 text-blue-500" />
                    </button>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="bg-gray-900 p-2"></div>
            
          </>
        ))}
      </main>
    </>
  );
};

export default CareerAvailability;
