import React from "react";

const CareerHeader = () => {
  return (
    <>
      <main className="contact-options-grid mt-16">
        <div className="bg-gray-900"></div>
        <ul className="flex flex-col space-y-3 bg-gray-900 p-4 sm:p-8 lg:p-12">
          <li>
            <h1 className="text-2xl text-blue-500 font-medium">Careers</h1>
          </li>
          <div className="flex flex-col">
            <li>
              <h2 className="text-3xl leading-normal text-gray-300 font-light line-through">
                Work for Us
              </h2>
            </li>
            <li>
              <h2 className="text-4xl leading-normal text-blue-500 font-medium">
                Work with Us
              </h2>
            </li>
          </div>
          <li>
            <p className="text-base leading-relaxed text-gray-400 font-light md:pr-32 lg:pr-56">
              We at Kabira Mobility are developing the Future of Electric
              Mobility. Join the Team to help us achieve our Vision of a Cleaner
              & Smarter Mobility.
            </p>
          </li>
        </ul>
        <div className="bg-gray-900"></div>
        <div className="bg-gray-900 p-1"></div>
        <div className="bg-gray-900"></div>
        <div className="bg-gray-900"></div>
      </main>
    </>
  );
};

export default CareerHeader;
