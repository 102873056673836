import React, { useState } from "react";
import { Form, Formik, ErrorMessage, Field } from "formik";
import axios from "axios";
import * as Yup from "yup";
import formErrors from "../Global/formErrors";
import { graphql, useStaticQuery } from "gatsby";
import { DocumentAddIcon } from "@heroicons/react/outline";

const CareerForm = () => {
  const data = useStaticQuery(graphql`
    {
      allPrismicJobOpening {
        edges {
          node {
            data {
              job_title {
                text
              }
              job_location
              salary_ending
              salary_starting
              key_qualifications {
                html
              }
              job_summary {
                html
              }
              education_experience {
                html
              }
              key_responsibilities {
                html
              }
            }
          }
        }
      }
    }
  `);
  const GETFORM_FORM_ENDPOINT = "https://hook.integromat.com/lyje5m3io327tjic3bv2ech8etwe7wet";


  const [formStatus, setFormStatus] = useState(false);
  const [query, setQuery] = useState({
    name: "",
    email: "",
    mobileNumber: "",
  });

  const handleFileChange = () => (e) => {
    setQuery((prevState) => ({
      ...prevState,
      files: e.target.files[0],
    }));
  };

  const handleChange = () => (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setQuery((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    Object.entries(query).forEach(([key, value]) => {
      formData.append(key, value);
    });

    axios
      .post(
        GETFORM_FORM_ENDPOINT,
        formData,
        { headers: { Accept: "application/json" } }
      )
      .then(function (response) {
        setFormStatus(true);
        setQuery({
          name: "",
          email: "",
          mobileNumber: "",
        });
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  // const [isSent, setIsSent] = useState(false);

  // // const submitForm = (values) => {
  // //   axios({
  // //     headers: {
  // //       "Content-Type": "application/json",
  // //     },
  // //     method: "post",
  // //     url: "https://hook.integromat.com/neuff6f9zmaee3t81jo9wnw8a1ilkus8",
  // //     data: JSON.stringify(values),
  // //   })
  // //     .then((r) => {
  // //       console.log("Thanks!", values);
  // //     })
  // //     .catch((r) => {
  // //       console.log("Failed", values);
  // //     });
  // // };

  // // const validationSchema = Yup.object({
  // //   firstName: Yup.string()
  // //     .matches(/^[A-Za-z ]*$/, "Please enter valid name")
  // //     .required("This Field is Required"),
  // //   lastName: Yup.string()
  // //     .matches(/^[A-Za-z ]*$/, "Please enter valid name")
  // //     .required("This Field is Required"),
  // //   eMail: Yup.string()
  // //     .email("Please give your E-Mail")
  // //     .required("Please fill your E-Mail Address"),
  // //   mobileNumber: Yup.string().matches(/^[6-9]\d{9}$/, {
  // //     message: "Please enter valid number.",
  // //     excludeEmptyString: false,
  // //   }),
  // //   position: Yup.string().required("This Field is Required"),
  // //   url: Yup.string()
  // //     .url("Please enter a Valid URL")
  // //     .required("This Field is Required"),
  // //   mainMessage: Yup.string().required("This Field is Required"),
  // // });

  return (
    <main className="contact-options-grid">
      <div className="bg-gray-900 p-2"></div>
      <div className="bg-gray-900 p-2"></div>
      <div className="bg-gray-900 p-2"></div>
      <div className="bg-gray-900 p-2"></div>
      <div className="bg-gray-900 p-4">
        {!formStatus ? (
          // <Formik
          //   initialValues={{
          //     firstName: "",
          //     lastName: "",
          //     eMail: "",
          //     mobileNumber: "",
          //     position: "",
          //     url: "",
          //     mainMessage: "",
          //   }}
          //   onSubmit={(values, actions) => {
          //     submitForm(values);
          //     console.log(values);
          //     actions.setSubmitting(false);
          //     actions.resetForm();
          //   }}
          //   validationSchema={validationSchema}
          // >
          //   <Form className="form-grid">
          //     <ul className="flex flex-col pb-8 space-y-3 col-span-2 border-b border-dashed border-gray-700">
          //       <li className="text-3xl font-medium sm:text-4xl text-blue-500">
          //         Didn't Find the Perfect Opporunity?
          //       </li>
          //       <li className="text-base text-gray-400 font-light">
          //         Fill out the Form Below, and our team will get back to you
          //       </li>
          //     </ul>
          //     <div className="form-field-container">
          //       <label htmlFor="firstName" className="form-label">
          //         First Name
          //       </label>
          //       <Field
          //         type="text"
          //         id="firstName"
          //         name="firstName"
          //         placeholder="First Name"
          //         className="form-input-field"
          //       />
          //       <ErrorMessage name="firstName" component={formErrors} />
          //     </div>
          //     <div className="form-field-container">
          //       <label htmlFor="lastName" className="form-label">
          //         Last Name
          //       </label>
          //       <Field
          //         type="text"
          //         id="lastName"
          //         name="lastName"
          //         placeholder="Last Name"
          //         className="form-input-field"
          //       />
          //       <ErrorMessage name="lastName" component={formErrors} />
          //     </div>
          //     <div className="form-field-container">
          //       <label htmlFor="eMail" className="form-label">
          //         E-Mail Address
          //       </label>
          //       <Field
          //         type="text"
          //         id="eMail"
          //         name="eMail"
          //         placeholder="E-Mail Address"
          //         className="form-input-field"
          //       />
          //       <ErrorMessage name="eMail" component={formErrors} />
          //     </div>
          //     <div className="form-field-container">
          //       <label htmlFor="mobileNumber" className="form-label">
          //         Mobile Number
          //       </label>
          //       <ul className="flex flex-row">
          //         <li className="flex flex-col items-center justify-center px-3 rounded-l bg-gray-800 shadow border-gray-700 border border-r-0 text-gray-400">
          //           +91
          //         </li>
          //         <li className="flex-1">
          //           <Field
          //             type="text"
          //             id="mobileNumber"
          //             name="mobileNumber"
          //             placeholder="91234567890"
          //             className="form-input-field rounded-r rounded-l-none"
          //           />
          //         </li>
          //       </ul>
          //       <ErrorMessage name="mobileNumber" component={formErrors} />
          //     </div>
          //     <div className="form-field-container">
          //       <label htmlFor="position" className="form-label">
          //         Job Position
          //       </label>
          //       <Field
          //         type="text"
          //         id="position"
          //         name="position"
          //         placeholder="Job Position"
          //         className="form-input-field"
          //       />
          //       <ErrorMessage name="position" component={formErrors} />
          //     </div>
          //     <div className="form-field-container">
          //       <label htmlFor="url" className="form-label">
          //         Resume URL (Google Drive)
          //       </label>
          //       <Field
          //         type="url"
          //         id="url"
          //         name="url"
          //         placeholder="Resume Link (Google Drive)"
          //         className="form-input-field"
          //       />
          //       <ErrorMessage name="companyName" component={formErrors} />
          //     </div>
          //     <div className="form-field-container col-span-2">
          //       <label htmlFor="mainMessage" className="form-label">
          //         Message
          //       </label>
          //       <Field
          //         type="textarea"
          //         as="textarea"
          //         e
          //         id="mainMessage"
          //         name="mainMessage"
          //         placeholder="Type your Message here"
          //         className="form-input-field h-24"
          //       />
          //       <ErrorMessage name="mainMessage" component={formErrors} />
          //     </div>
          //     <div className="form-field-container col-span-2 flex flex-row justify-end">
          //       <button
          //         type="submit"
          //         className="contact-form-button border-r-0"
          //       >
          //         Submit
          //       </button>
          //     </div>
          //   </Form>
          // </Formik>

          <>
            <form
              acceptCharset="UTF-8"
              method="POST"
              enctype="multipart/form-data"
              id="gatsbyForm"
              onSubmit={handleSubmit}
              className="grid grid-cols-1 md:grid-cols-2 gap-4"
            >
              <div className="flex flex-col space-y-3 col-span-2 md:col-span-1">
              <label for="fullName" className="text-lg text-gray-300">Full Name</label>
                <input
                  type="text"
                  className="form-input-field"
                  placeholder="Enter your name"
                  required
                  name="name"
                  value={query.name}
                  onChange={handleChange()}
                />
              </div>
              <div className="flex flex-col space-y-3 col-span-2 md:col-span-1">
                <label for="eMail" className="text-lg text-gray-300">Email address</label>
                <input
                  type="email"
                  className="form-input-field"
                  placeholder="Enter your email"
                  required
                  name="email"
                  value={query.email}
                  onChange={handleChange()}
                />
              </div>
              <div className="flex flex-col space-y-3 col-span-2 md:col-span-1">
              <label for="mobileNumber" className="text-lg text-gray-300">Mobile Number</label>
                <input
                  type="text"
                  className="form-input-field"
                  placeholder="Enter your Mobile Number"
                  required
                  name="mobileNumber"
                  value={query.mobileNumber}
                  onChange={handleChange()}
                />
              </div>
              
              <div className="">
                <label className="text-lg text-gray-300">Upload your Resume</label>
                <input name="file" type="file" className = "file:mr-4 file:py-2 file:px-4
      file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-sky-300 file:text-sky-800 hover:file:bg-sky-400 text-gray-500 my-6" onChange={handleFileChange()} />
              </div>
              {formStatus ? (
                <div className="text-success mb-2">
                  Your message has been sent.
                </div>
              ) : (
                ""
              )}
              <button type="submit" className="contact-form-button bg-transparent hover:bg-sky-500 text-sky-400 hover:text-white border-sky-500 border-[1px] hover:border-[0px] col-span-2">
                Submit
              </button>
            </form>
          </>
        ) : (
          <div className="flex flex-col items-center justify-center py-16 px-8">
            <p className=" text-2xl text-gray-400">
              {" "}
              Thank you For Submitting the Form,
            </p>
            <p className="pt-4 text-xl font-light text-gray-300">
              Please check your Inbox! for further instructions.
            </p>
          </div>
        )}
      </div>
      <div className="bg-gray-900 p-2"></div>
    </main>
  );
};

export default CareerForm;
