import React from "react";
import CareerAvailability from "../components/Careers/careerAvailability";
import CareerForm from "../components/Careers/careerForm";
import CareerHeader from "../components/Careers/careerHeader";
import Layout from "../components/Global/layout";
import SEO from "../components/seo/SEO";

const Careers = () => {
  return (
    <>
    <SEO />
      <Layout>
        <main>
          <CareerHeader />
          <CareerAvailability />
          <CareerForm />
        </main>
      </Layout>
    </>
  );
};

export default Careers;
